<template>  

    <b-card title="Set Sellers Customer Intimation Events">
            <b-spinner v-if="spinnerShow == true" style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow">
            </b-spinner>
                <b-row v-if="mainData == true"> 
                    <b-col cols="6">
                        <b-form-group
                            label="Select Seller(s)"
                            label-for="select_seller"
                            description="seller_id --- company_name(brand)"
                        >
                        <v-select
                            v-model="selectedSellers"
                            placeholder="Select Sellers"
                            :options="option"
                            :key="componentKey"
                            id="select_seller"
                            @input="getSellerCI"
                        />
                        </b-form-group>
                        
                    </b-col>
                    
                    <b-col cols="12">
                        <b-form-checkbox
                        
                        name="shipment-dispatched"
                        v-model="shipment_dispatched"
                        switch
                        inline
                        >
                        Shipment Dispatched
                        </b-form-checkbox>
                        <b-form-checkbox
                        
                        name="shipment-delivered"
                        v-model="shipment_delivered"
                        switch
                        inline
                        >
                        Shipment Delivered
                        </b-form-checkbox>
                        <b-form-checkbox
                        checked="false"
                        name="order-cancellation"
                        v-model="order_cancellation"
                        switch
                        inline
                        >
                        Order Cancellation
                        </b-form-checkbox>
                        <b-form-checkbox
                        checked="false"
                        name="order-confirmation"
                        v-model="order_confirmation"
                        switch
                        inline
                        >
                        Order Confirmation
                        </b-form-checkbox>
                    </b-col>
                    <br><br><br>
                    <b-col cols="12">
                        <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="sendForm"
                        >
                        Submit
                        </b-button>
                    </b-col>
                    </b-row>
                
    </b-card>

</template>
<script>
import {
  BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown,BCard,BButton,BTabs,BTab,BFormFile,BForm,BRow,BCol,BSpinner,BFormCheckbox
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import pages from '@/router/routes/pages'
import Ripple from 'vue-ripple-directive'
import reportStore from '@/store'

import VuePapaParse from 'papaparse'

export default {
  components: {
    VueGoodTable,
    vSelect,
    BSpinner,
    BFormCheckbox,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormFile,
    BTab,
    BTabs,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BButton,
    VuePapaParse,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      shipment_dispatched:false,
      shipment_delivered:false,
      order_cancellation:false,
      order_confirmation:false,
      selectedSellers:[],
      option:[],
      isLoadingProp: false,
      datas:[],
      componentKey:0,
      mainData:true,
      spinnerShow:false
    }
  },

  computed: {
    

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

   created() {
    this.$http.post('get-sellers-all')
        .then(res => { 
        if(res.status == 200){
            for(var i=0;i<res.data.data.length;i++){
                // console.log(res.data.data)
                this.option[i] = res.data.data[i].id+' --- '+res.data.data[i].company_name+'('+res.data.data[i].brand+')';
            }
            this.componentKey = 1;
        }
    })
          
  },

  methods: {
    getSellerCI: function(seller_id){
        var formData = new FormData();
        formData.append('seller_id', seller_id);
        this.spinnerShow = true;
        this.$http.post('get-seller-intimations-fields', formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            // this.showTable = false;
            this.mainData = true;
            this.spinnerShow = false;

            if(res.data.error == '0' && res.data.data){
                this.shipment_dispatched = Boolean(res.data.data.is_shipment_dispatched_viewable);
                this.shipment_delivered = Boolean(res.data.data.is_shipment_delivered_viewable);
                this.order_cancellation = Boolean(res.data.data.is_order_cancellation_viewable);
                this.order_confirmation = Boolean(res.data.data.is_order_confirmation_viewable);
            }
            else {
                this.shipment_dispatched = false;
                this.shipment_delivered = false;
                this.order_cancellation = false;
                this.order_confirmation = false;
            }
            this.toast((res.data.error == '1') ? 'danger' : 'success', res.data.message, 'Seller Customer Intimation Fields'); 
        })
    },
    sendForm(){
        var formData = new FormData();
        formData.append('shipment_dispatched', this.shipment_dispatched);
        formData.append('shipment_delivered', this.shipment_delivered);
        formData.append('order_cancellation', this.order_cancellation);
        formData.append('order_confirmation', this.order_confirmation);
        formData.append('seller_id', this.selectedSellers);
        this.mainData = false;
        this.spinnerShow = true;
        this.$http.post('save-seller-intimations-fields', formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            // this.showTable = false;
            this.mainData = true;
            this.spinnerShow = false;

            if(res.data.error == '0'){
                this.shipment_dispatched = false;
                this.shipment_delivered = false;
                this.order_cancellation = false;
                this.order_confirmation = false;
                this.selectedSellers = [];
            }
            this.toast((res.data.error == '1') ? 'danger' : 'success', res.data.message, 'Seller Customer Intimation Fields'); 
        })
    },

    toast(variant = null, message = 'Toast body content', title = variant) {
				
        message = this.$createElement('strong', { class: 'mr-2' }, message)

        this.$bvToast.toast(message, {
            title: title,
            variant,
            solid: false,
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>